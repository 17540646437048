import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Menu, Calendar, ArrowRight, X } from 'lucide-react';

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}>
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

// Define Card components
const Card = ({ className, children }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="p-6">{children}</div>;
const CardContent = ({ children }) => <div className="px-6 py-2">{children}</div>;
const CardFooter = ({ children }) => <div className="p-6">{children}</div>;
const CardTitle = ({ className, children }) => (
  <h2 className={`text-xl font-semibold ${className}`}>{children}</h2>
);
const CardDescription = ({ className, children }) => (
  <p className={`mt-2 ${className}`}>{children}</p>
);

// Define Button component
const Button = ({ variant, className, children, ...props }) => (
  <button
    className={`px-4 py-2 rounded ${className} ${
      variant === 'link' ? 'bg-transparent' : 'bg-[#6a9ad4]'
    }`}
    {...props}
  >
    {children}
  </button>
);

// Define Badge component
const Badge = ({ variant, className, children }) => (
  <span
    className={`px-2 py-1 rounded-full text-sm font-semibold ${className} ${
      variant === 'secondary' ? 'bg-[#27548a] text-[#c5d8ed]' : ''
    }`}
  >
    {children}
  </span>
);

const Modal = ({ isOpen, onClose, title, content }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
        >
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            className="bg-gradient-to-br from-[#a3c1e4] to-[#3b79be] rounded-lg p-6 max-w-lg w-full"
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-[#280137]">{title}</h2>
              <button onClick={onClose} className="text-[#280137] hover:text-[#3b79be]">
                <X size={24} />
              </button>
            </div>
            <div className="text-[#280137] modal-content [&_a]:text-white [&_a]:hover:text-[#a6b4c4] [&_a]:transition-colors [&_a]:duration-300">
              {content}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] transition-colors"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <span className="font-outhill text-white">NechepsO</span>
  </h1>
);

const AnnouncementCard = ({ title, date, description, category, content, isNew }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 }
        }}
        transition={{ duration: 0.5 }}
        className="relative"
      >
        <Card className="bg-gradient-to-br from-[#a3c1e4] to-[#3b79be] bg-opacity-30 backdrop-blur-lg rounded-lg p-6 shadow-lg transform hover:scale-105 transition-all duration-300 relative z-10">
          {isNew && (
            <div className="absolute bottom-2 right-2 bg-white text-[#280137] font-bold py-1 px-3 z-20 rounded-lg">
              New
            </div>
          )}
          <CardHeader>
            <div className="flex justify-between items-start">
              <CardTitle className="text-2xl font-bold text-[#280137]">{title}</CardTitle>
              <Badge variant="secondary" className="bg-[#27548a] text-[#c5d8ed]">
                {category}
              </Badge>
            </div>
            <CardDescription className="text-[#280137] flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              {date}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-[#280137]">{description}</p>
          </CardContent>
          <CardFooter>
            <Button 
              variant="link" 
              className="text-white hover:text-[#3b79be] transition-colors duration-300"
              onClick={() => setIsModalOpen(true)}
            >
              Read More <ArrowRight className="ml-2 w-4 h-4" />
            </Button>
          </CardFooter>
        </Card>
      </motion.div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        content={content}
      />
    </>
  );
};

const AnnouncementsPage = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset;
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax');
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed;
        const yPos = -(scrollPosition * speed);
        el.style.transform = `translateY(${yPos}px)`;
      });
    };
    window.addEventListener('scroll', parallaxEffect);
    return () => window.removeEventListener('scroll', parallaxEffect);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0f2137] via-[#3776c1] to-[#a3c1e4] text-white overflow-hidden">
      
      {/* Parallax stars */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden">
        {[...Array(100)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute rounded-full bg-white parallax"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: Math.random() * 4 + 1,
              height: Math.random() * 4 + 1,
            }}
            data-speed={Math.random() * 0.5 + 0.2}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.7, 1, 0.7],
            }}
            transition={{
              repeat: Infinity,
              duration: Math.random() * 3 + 2,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>

      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`}>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-90' : 'opacity-0'}`}>
          <div className="absolute inset-0 bg-gradient-to-r from-[#a3c1e4] via-[#3776c1] to-[#0f2137]"></div>
        </div>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>

      <main className="container mx-auto px-4 pt-40 pb-16">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-5xl md:text-7xl font-bold text-center mb-12 text-[#c5d8ed]"
        >
          Announcements
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-2xl text-center mb-12 max-w-3xl mx-auto text-[#e5eef7]"
        >
          Stay up to date with the latest news and updates from <NechepsoText className="font-bold" />
        </motion.p>
        <div className="grid md:grid-cols-2 gap-8">
        <AnnouncementCard
            title="Catch the Team on the Celestial Compass!"
            date="October 1st, 2024"
            description="The TMT team will be on the Celestial Compass podcast with Kathy Biehl to discuss Nechepso and Third House on October 7th!"
            category="Conference"
            content={<div>
              <p>Make sure to tune in to see the team on the Celestial Compass with Kathy Biehl!</p><br />
              <p>The show airs on Monday, October 7th, 2024 at 2:00 PM PDT/5:00 PM EDT.</p><br />
              <p>Some of the things we will be discussing include:</p><br />
              <ul className="list-disc list-inside mt-2">
                <li>Nechepso</li>
                <li>Third House</li>
                <li>What set's Nechepso apart from other astrology software</li>
                <li>What the future holds for both Nechepso and Third House</li>
              </ul>
              <p>It will be broadcast live from the <a href="https://www.youtube.com/@omtimestv" target="_blank" rel="noopener noreferrer">@omtimestv</a> YouTube channel.</p>
              <p>See you there!</p>
            </div>}
            isNew={true}
          />
          <AnnouncementCard
            title="Patrick Watson to speak at NORWAC 2025!"
            date="September 27th, 2024"
            description="Catch Patrick Watson speaking at NORWAC which is taking place from May 22nd-26th! Mark your calendar!."
            category="Conference"
            content={<div>
              <p>Next year catch Patrick Watson speaking at the NORWAC conference in Tukwila, Washington.</p><br />
              <p>The event runs between May 22nd and May 26th and features many great speakers and events.</p><br />
              <p>Here's what Patrick is slated to speak about at the conference:</p><br />
              <ul className="list-disc list-inside mt-2">
                <li>Exploring Planetary Activations</li>
                <li>Friday, May 23rd, 2025, 2:45 pm PDT</li><br />
                <li>Introduction to Babylonian Goal Years</li>
                <li>Sunday, May 25th, 2025, 3:45 pm PDT</li><br />
              </ul>
              <p>Registration will open for the conference on October 1st, 2024.</p>
              <p className="mt-4">For more information, visit <a href="https://norwac.net/" target="_blank" rel="noopener noreferrer">norwac.net</a> for additional details.</p>
            </div>}
            isNew={true}
          />
          <AnnouncementCard
            title="Beta Testing Dates Announced!"
            date="September 23rd, 2024"
            description="We now have a confirmed date range for when we expect beta testing to begin! Check out the details inside."
            category="Nechepso"
            content={<div>
              <p>We are excited to announce that we have set a tentative date range for when we expect beta testing to begin.</p><br />
              <p>The time range we are looking to ship out the software is between October 17th and October 22nd.</p><br />
              <p>Here's what you can expect if you're registered as a beta tester:</p><br />
              <ul className="list-disc list-inside mt-2">
                    <li>A download email with a link to the software</li>
                    <li>Registration will be your Order ID and Confirmation Number</li>
                    <li>You will be able to submit feedback internally within the software</li>
                    <li>We need all feedback submitted by the end of the month of October</li>
              </ul>
              <p className="mt-4">We will alert everyone here on the site, on social media, and via email when the testing begins. See you soon!</p>
            </div>}
            isNew={true}
          />
          <AnnouncementCard
            title="Updated Website with Nechepso Roadmap"
            date="September 15th, 2024"
            description="As things are heating up, we wanted to provide a roadmap for what to expect in the near future!"
            category="Update"
            content={<div>
              <p>We're excited to reveal our updated website, complete with an in-depth look at Nechepso's upcoming developments.</p><br />
              <p>The roadmap outlines key milestones, giving you a clear view of where we're heading and how we plan to get there.</p><br />
              <p>Here's a sneak peek of what you can expect:</p><br />
              <ul className="list-disc list-inside mt-2">
                    <li>Beta testing beginning soon!</li>
                    <li>Official launch of Nechepso 1.0</li>
                    <li>Launch of the open-source Meridian Codex atlas</li>
                    <li>Fully updated UI rich with new features for Third House</li>
              </ul>
              <p className="mt-4">Stay tuned for regular updates as we continue to build the future of astrology with Nechepso!</p>
            </div>}
          />
          <AnnouncementCard
            title="Nechepso Beta Test Signups"
            date="August 17th, 2024"
            description="We're starting to send out invitations to sign up for Nechepso's beta testing. If you've already purchased, make sure to secure your spot!"
            category="Nechepso"
            content={<div>
              <p>We're excited to begin the signup process for Nechepso's beta testing!</p><br />
              <p>If you've already paid for the software, now's your chance to join the beta test. We know the signup process can be lengthy, so we're giving everyone time to register, but we won't keep it open forever. Be on the lookout in your email for link to register and get started!</p><br />
              <p>As a beta tester, you'll have the opportunity to:</p>
              <ul className="list-disc list-inside mt-2">
                <li>Explore every feature of Nechepso and provide feedback before its implementation</li>
                <li>Test out the Nechepso AI before its release</li>
                <li>Provide invaluable feedback to help shape Nechepso's future</li>
                <li>With Nechepso - Beta testing is for life! Every new feature, forever, you get to test it first!</li>
              </ul>
              <p className="mt-4">Don't miss your chance to sign up and be part of the journey!</p>
            </div>}
          />
          <AnnouncementCard
            title="Third House is Live!"
            date="June 14th, 2024"
            description="Third House Social is now live for public testing! The first and only social network built exclusively for astrology. We value your feedback!"
            category="Third House"
            content={<div>
              <p>We're excited to announce the public testing phase of Third House, the first social network created specifically by astrologers, for astrology, featuring astrological tools and insights built directly into the platform.</p>
              <p>As we continue development, your feedback is crucial to help us refine the features and create a truly unique experience for the astrology community. We are looking to deploy a major update by December 2024.</p>
              <ul className="list-disc list-inside mt-2">
                <li>Explore astrological posts and insights in real-time</li>
                <li>Engage with a community of fellow astrology enthusiasts</li>
                <li>Be part of shaping the future of this groundbreaking network</li>
              </ul>
              <p className="mt-4">Visit us today at <a href="https://www.thirdhousesocial.com" target="_blank" rel="noopener noreferrer">www.thirdhousesocial.com</a> and join the astrological conversation!</p>
            </div>}
          />

          <AnnouncementCard
            title="Introducing: Nechepso & Nechepso AI"
            date="May 7th, 2024"
            description="Nechepso is here! Today we launch the first-ever demo of Nechepso AI for the world to see!"
            category="Nechepso"
            content={<div>
              <p>We're proud to introduce Nechepso, the next generation of astrology software, along with our first public demo of Nechepso AI!</p>
              <p>This demo showcases the unparalleled accuracy of Nechepso AI in astrological analysis, a leap beyond frontier models like ChatGPT, Claude, and Gemini in astrological knowledge.</p>
              <ul className="list-disc list-inside mt-2">
                <li>See Nechepso AI tackle the most specific sets of astrological data</li>
                <li>Explore how our AI sets a new standard in astrological interpretation</li>
                <li>Get a glimpse of the future of astrology software</li>
              </ul>
              <p className="mt-4">Watch the demo now: <a href="https://www.youtube.com/watch?v=kXucJu8I-SQ" target="_blank" rel="noopener noreferrer">Nechepso AI Demo</a></p>
            </div>}
          />
        </div>
      </main>

      <footer className="bg-[#2a5688] py-8 relative">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p className="text-[#c5d8ed]">&copy; 2024 Thema Mundi Technologies. All rights reserved.</p>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
          backgroundSize: '50px 100%',
          opacity: 0.2,
        }}></div>
      </footer>
    </div>
  );
};

export default AnnouncementsPage;